body {
  max-width: 650px;
}
.tag {
  line-height: 1.6;
}
fieldset {
  border-style: dashed;
  border-color: #aaa;
}
